import React, { useEffect, useState } from "react";
import Post from './post'
// import { Link } from "@reach/router";

const Posts = () => {
    console.log("h")
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const getPosts = async () => {
            const resp = await fetch(
                "https://workers.shubham-parulekar.workers.dev/posts"
            );
            const postsResp = await resp.json();
            setPosts(postsResp);
        };

        getPosts();
    }, []);

    return (
        <div className="Post-container">
            <h1>Feed</h1>
            {posts.map((post) => (
                <Post key={post.id} post={post} />
            ))}
        </div>
    );
};

export default Posts;