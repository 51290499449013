import './Comments.css';
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Comment = ({ comment }) => {
    const increment = async () => {
        await updateRating("increment")
    }

    const decrement = async () => {
        await updateRating("decrement")
    }

    const updateRating = async (type) => {
        const data = {
            id: comment.id,
            operation: type
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        const response = await fetch('https://workers.shubham-parulekar.workers.dev/post/comment/rating', requestOptions);
        if (!response.ok) {
            alert("There was an error while commenting.")
            return;
        }
        setState({ count: (await response.json()).rating });
    }

    const [state, setState] = useState({
        count: comment.rating
    })

    return (
        <div className="Comment">
            <div className="CommentInfo">
                <div className="CommentHeader"><h2>{comment.title}</h2></div>
                <div className="Commenter">@{comment.username}</div>
            </div>
            <div>
                <div className='CommentRating'>
                    <div>{<button onClick={increment}>+</button>}</div>
                    <div><span>{state["count"]}</span></div>
                    <div>{<button onClick={decrement}>-</button>}</div>
                </div>
                <p className="CommentContent">{comment.content}</p>
            </div>
        </div>
    );
};

export default Comment;