import './Posts.css';
import './Comments.css';
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Post = ({ post }) => {
    const increment = async () => {
        await updateRating("increment")
    }

    const decrement = async () => {
        await updateRating("decrement")
    }

    const updateRating = async (type) => {
        const data = {
            id: post.id,
            operation: type
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        const response = await fetch('https://workers.shubham-parulekar.workers.dev/post/rating', requestOptions);
        if (!response.ok) {
            alert("There was an error while posting.")
            return;
        }
        setState({ count: (await response.json()).rating });
    }

    const [state, setState] = useState({
        count: post.rating
    })

    return (
        <div className="Post">
            <div className="PostInfo">
                <div className="PostHeader"><Link to={`/post/${post.id}`}><h2>{post.title}</h2></Link></div>
                <div className="Poster">@{post.username}</div>
            </div>
            <div>
                <div className='PostRating'>
                    <div>{<button onClick={increment}>+</button>}</div>
                    <div><span>{state["count"]}</span></div>
                    <div>{<button onClick={decrement}>-</button>}</div>
                </div>
                <p className="PostContent">{post.content}</p>
            </div>
        </div>
    );
};

export default Post;