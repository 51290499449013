import './App.css';
import React, { useState } from "react";
import Home from './pages/Home'
import PostPage from './pages/PostPage'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="content-container">
          <header className="App-header">The Cloud</header>
          {/* <Link to="/">Home</Link>
          <Link to="/post">Post</Link> */}

          <Routes>
            <Route exact path="/" element={<Home />} />
            {/* id="post-42da4b43-4cea-4402-85b3-b3523e57ec2d"  */}
            <Route exact path="/post/:id" element={<PostPage />} />
          </Routes>
        </div>
        <footer className="footer-pin">
          <p>Shubham Atul Parulekar<br />
            <a href="mailto:shubham.parulekar@gmail.com">shubham.parulekar@gmail.com</a>
          </p>
        </footer>
      </div>
    </Router>
  );
}

export default App;