import React, { useEffect, useState } from "react";
import Comment from './comment'
// import { Link } from "@reach/router";

const Comments = ({ postId }) => {
    const [comments, setComments] = useState([]);

    useEffect(() => {
        const getComments = async () => {
            const resp = await fetch(
                `https://workers.shubham-parulekar.workers.dev/post/comment?postId=${postId}`
            );
            const commentsResp = await resp.json();
            setComments(commentsResp);
        };

        getComments();
    }, []);
    console.log("sadda"+postId);

    return (
        <div className="Comment-container">
            <h1>Comments</h1>
            {comments.map((comment) => (
                <Comment key={comment.id} comment={comment} />
            ))}
        </div>
    );
};

export default Comments;