import '../App.css';
import Posts from '../components/posts'
import Modal from 'react-modal';
import React, { useState } from "react";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: '40%',
    bottom: '20%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#282c34',
    color: 'white'
  },
};

// const = ()


Modal.setAppElement("#root")
const Home = () => {
  const [isModalOpen, toggleModal] = useState(false)
  const [state, setState] = React.useState({
    username: "",
    title: "",
    content: "",
    updatedKey: 1
  })


  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {};
    data["username"] = state["username"];
    data["title"] = state["title"];
    data["content"] = state["content"];
    const requestOptions = {
      method: 'POST',
      headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    const response = await fetch('https://workers.shubham-parulekar.workers.dev/posts', requestOptions);
    if (!response.ok) {
      alert("There was an error while posting.")
      return
    }
    toggleModal(false)
    setState({ username: "", title: "", content: "", updatedKey: -1 * state.updatedKey });
  }

  function handleChange(evt) {
    const value = evt.target.value;
    setState({ ...state, [evt.target.name]: value });
  }

  return (
    <div>
      <Posts key={state.updatedKey} />
      <button onClick={() => toggleModal(true)} className="button-new">+</button>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => toggleModal(false)}
        style={customStyles}
      >
        <h2 className='heading-modal'>New Post</h2>
        <div>
          <form id="post-form" onSubmit={handleSubmit} className="form-model">
            <label>
              <div className='input-pair'>
                <div className='input-label'>Username:</div>
                <input type="text" name="username" value={state.username} onChange={handleChange} required />
              </div>
            </label>
            <label>
              <div className='input-pair'>
                <div className='input-label'>Title:</div>
                <input type="text" name="title" value={state.title} onChange={handleChange} required />
              </div>
            </label>
            <label>
              <div className='input-pair'>
                <div className='input-label'>Post:</div>
                <input type="text" name="content" value={state.content} onChange={handleChange} required />
              </div>
            </label>
          </form>
        </div>
        <div>
          <button type="submit" form="post-form" value="Submit" className="button-model">Ok</button>
          <button onClick={() => toggleModal(false)} className="button-model">Close</button>
        </div>
      </Modal>
    </div>
  );
}

export default Home;